import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['subMenuToggle']
  
  connect() {
    this.state = {
      isOpen: false
    }
  }

  toggleSubMenu(event) {
    this.element.classList.toggle('is-open')
    this.state.isOpen = !this.state.isOpen
    if (this.state.isOpen) {
      this.element.closest('.has-sub-menu').classList.add('is-open')
    } else {
      this.element.closest('.has-sub-menu').classList.remove('is-open')
    }

  }
}
